<template>
  <header-app></header-app>
  <router-view/>
  <footer-app></footer-app>
  <cookies-bar></cookies-bar>
</template>


<script>
import HeaderApp from "./components/HeaderApp.vue";
import FooterApp from "./components/FooterApp.vue";
import CookiesBar from "./components/CookiesBar.vue";

export default {
  name : "App",
  components : {
    HeaderApp,
    FooterApp,
    CookiesBar
  }
}
</script>

<style lang="scss">
@import "./scss/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
body, p, h1, h2, h3, h4, a {
  margin:0;
  padding:0;
  font-family: 'Quicksand', sans-serif;
}

p {
  color:$textColor;
  text-align: justify;
}

p .focus, h1, h2, h3, h4 {
  color:$mainColor;
}

h1 {
  font-family: 'Allison', cursive;
  font-size:80px;
  font-weight: 200;
}

h2 {
  font-size:45px;
  font-weight: 400;
  letter-spacing: 2px;
}



.main {
  width: $box;
  max-width: 90%;
  margin:0 auto;
}

.mailto {
  color:$mainColor;
  text-decoration: none;
}

.mailto:hover {
  text-decoration: underline;
}


.copy {
  font-family: 'Quicksand', sans-serif;
  font-size:15px;
}
</style>
