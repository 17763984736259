<template>
    <div class="cookies-container" v-if="firstTime">
        <div class="cookies-content">
            <div class="cookies-text">
                <p>Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.</p>
            </div>
            <div class="cookie-btn-container">
                <div class="cookie-btn" @click="accept">OK</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "CookiesBar",
    data() {
        return {
            firstTime : false
        }
    },
    methods : {
        accept() {
            const d = new Date();
            d.setTime(d.getTime() + (7*24*60*60*1000)); //7jours
            let expires = "expires="+ d.toUTCString();
            document.cookie = "cookie-accept=true;"  + expires ;
            this.firstTime = false;
        }
    },
    created() {
        const getCookie = document.cookie.match(new RegExp('(^| )cookie-accept=([^;]+)'))
        if( !getCookie) {
            this.firstTime = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
.cookies-container {
    width:calc(100% - 20px);
    padding:15px 10px;
    background:$mainColor;
    position:fixed;
    bottom:0;
    z-index:999;
}

.cookies-content {
    width:$box;
    max-width: 90%;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.cookie-btn {
    padding:5px 10px;
    width:80px;
    background:#fff;
    color:$mainColor;
    border-radius:20px;
    display: flex;
    justify-content: center;
    font-weight:600;
    cursor: pointer;
    transition: 0.5s;
}

.cookie-btn:hover {
    background:$textColor;
    color:#fff;
}

@media screen and (max-width:768px) {
    .cookies-content {
        flex-direction: column;
    }

    .cookie-btn {
        margin-top:20px;
    }
}

</style>