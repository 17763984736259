import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      publicRoute: true,
      title: 'Mindfulness Pilates Studio',
      metaTags: [
       {
         name: 'description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       },
       {
         property: 'og:description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       }
      ]
    }
  },
  {
    path: '/legal-notices',
    name: 'LegalNotices',
    component: () => import('../views/LegalNotices.vue'),
    meta: {
      publicRoute: true,
      title: 'Mindfulness Pilates Studio',
      metaTags: [
       {
         name: 'description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       },
       {
         property: 'og:description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       }
      ]
    }
  },
  {
    path: '/cgv',
    name: 'CGV',
    component: () => import('../views/CGV.vue'),
    meta: {
      publicRoute: true,
      title: 'Mindfulness Pilates Studio',
      metaTags: [
       {
         name: 'description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       },
       {
         property: 'og:description',
         content: 'Studio de Pilates en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.'
       }
      ]
    }
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }else {
      return { top :0 }
    }
  },
})

export default router
