<template>
    <header :class="{scrolling: pos || $route.name == 'LegalNotices' || $route.name == 'CGV'}">
        <div class="header-container">
            <div class="logo-container">
                <router-link to="/#top">
                    <div class="logo-desk">
                        <img src="./../assets/Logo_MPS.png" alt="logo MPS" />
                    </div>
                    <div class="logo-mobile">
                        <img src="./../assets/Logo_MPS.png" alt="logo MPS" v-if="pos" />
                        <img src="./../assets/logo_MPS_blanc.png" alt="logo MPS" v-else  />
                    </div>
                </router-link>
            </div>
            <div class="menu-container">
                <nav class="menu-desk">
                    <router-link class="menu-link" to="/#cours">Les cours</router-link>
                    <router-link class="menu-link" to="/#contact">Contact</router-link>
                </nav>
                        <!-- MENU RESP -->
                <div class="menu-header-resp">
                    <div class="burger-nav" @click="open = !open" :class="open ? 'open' : ''" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav class="menu-header-resp-content" v-if="open">
                        <router-link class="menu-link" to="/#cours" @click="open = false">Les cours</router-link>
                        <router-link class="menu-link" to="/#contact" @click="open = false">Contact</router-link>                        
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name : "HeaderApp",
    data() {
        return {
            pos : false,
            open: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.open = false;
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods : {
        handleScroll() {   
            window.scrollY > 0 ? this.pos = true : this.pos = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

header {
    height:120px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:0.5s;
    position: fixed;
    top:0;
    width: 100%;
    background: transparent;
    z-index:99;
}

header.scrolling {
    background:#fff;
    box-shadow: 3px 0 5px 5px rgba(0,0,0,0.2);
    height:80px;
}

.header-container {
    width:$box;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

img {
   width:320px;
   height:auto; 
}

.menu-link {
    color:#fff;
    transition: 0.5s;
    text-decoration: none;
    font-size:20px;
    margin:0 20px;
    letter-spacing: 2px;
}

.menu-link:hover, header.scrolling .menu-link:hover {
    color:$mainColor;
}

header.scrolling .menu-link {
    color:$textColor;
}

/* MENU RESP */
    .menu-header-resp {
        display: none;
    }

    .burger-nav {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 50px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 7px;
        width: 100%;
        background: #7c7c7c;
        border-radius: 7px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    header.scrolling .burger-nav span {
        background: $mainColor
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 14px;
    }

    .burger-nav span:nth-child(4) {
        top: 28px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 120px;
        width: 300px;
        right: 0;
        background: #fff;
        //border-bottom: 1px solid $textColor;
        display: flex;
        flex-direction: column;
        box-shadow: 0 5px 5px 0px rgba(0,0,0,0.2) ;
    }

    header.scrolling .menu-header-resp-content  {
        top:80px;
    }

    .menu-header-resp-content .menu-link {
        width: calc(100% - 40px);
        border: none;
        height: 40px;
        justify-content: flex-start;
        padding: 0 20px;
        align-items: center;
        text-decoration: none;
        display: flex;
    }

    .menu-header-resp-content .menu-link .menu-text {
        display: flex;
        position: relative;
        width: calc(100% - 22px);
        height: 40px;
        align-items: center;
        bottom: 0;
        text-decoration: none;
        text-align: left;
        justify-content: flex-start;
        margin-left: 20px;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item {
        background: $mainColor;
    }

    .menu-header-resp-content .router-link-exact-active .menu-highlight {
        display: none;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item .icon-menu:before,
    .menu-header-resp-content .router-link-exact-active.menu-item .menu-text {
        color: #fff;
    }

    .link-ext-resp.menu-link {
        color:#fff;
        margin:0;
        display: flex;
        align-items: center;
        padding:0 40px;
    }

    .logo-mobile {
        display: none;
    }

@media screen and (max-width:768px) {
    .menu-desk {
        display: none;
    }

    .logo-mobile {
        display: flex;
    }

    .logo-desk {
        display: none;
    }

    header {
        background:rgba(#fff, 0.8);
    }

    .menu-header-resp {
        display: flex;
    }

    .menu-link {
        color:$textColor;
    }

    img {
        width:250px;
    }
}
</style>

