<template>
    <footer>
        <div class="logo-container">
            <img src="./../assets/STOTT-logo_bronze_RGB.jpg" alt="logo Scott pilates" />
            <img src="./../assets/merrithew.png" alt="logo merrithew" /> 
        </div>
        <p>Copyright © {{ currentYear }} MINDFULNESS PILATES STUDIO – All Rights Reserved - Design & Development by <a href="https://synthes3dweb.com/" target="_blank">SYNTHES’3D WEB</a> | <router-link to="/legal-notices">Mentions légales</router-link> | <router-link to="/cgv">CGV</router-link></p>
    </footer>
</template>

<script>
export default {
    name : "FooterApp",
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

footer {
    background:$mainColor;
    padding:10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:center;
}

footer p {
    text-align: center;
    max-width: 90%;
    font-size:15px;
    color:#fff;
}

footer p a {
    color:#fff;
    text-decoration: none;
}

footer p a:hover {
    text-decoration: underline;
}

.logo-container {
    display: flex;
    justify-content: center;
    padding:10px 0;
}

.logo-container img {
    margin:20px;
}

@media screen  and (max-width:768px) {
    footer p {
        font-size:12px;
        line-height: 1.5em;
    }

    .logo-container {
        flex-direction: column;
        align-items: center;
    }

    .logo-container img {
        margin:10px;
    }
}


</style>